import {
  Button,
  DataGrid,
  Drawer,
  Heading,
  Icon,
  IconButton,
  Layout,
  Stack,
  TabItem,
  Tabs,
  Text,
  TextField,
} from "@fleet.co/tarmac";
import { faChevronDown, faChevronUp, faEdit } from "@fortawesome/pro-regular-svg-icons";
import { queryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { Suspense, useContext, useState } from "react";
import ApiService from "src/tools/ApiService";
import UserContext from "src/tools/UserContext";
import "./CopilotPrompts.style.css";

export const authorizedCopilotPromptUsersEmails = [
  "armelle.casanova@fleet.co",
  "guy.houot@fleet.co",
  "robin.marillia@fleet.co",
  "geoffroy.danest@fleet.co",
  "developer@fleet.co",
  "developer+deploy@fleet.co",
];

const PROMPT_TYPES = {
  ADMIN: "admin",
  EMPLOYEE: "employee",
} as const;

const TABS = [
  { value: PROMPT_TYPES.ADMIN, label: "Admin" },
  { value: PROMPT_TYPES.EMPLOYEE, label: "Employee" },
];

type PromptType = (typeof PROMPT_TYPES)[keyof typeof PROMPT_TYPES];

type CopilotPromptType = {
  id: number;
  title: string | null;
  prompt: string;
  likes: number;
  dislikes: number;
  status: "active" | "inactive" | "deleted";
  created_at: string;
  updated_at: string;
  type: PromptType;
};

export const copilotQueries = {
  lists: () => ["copilotPrompts"],
  list: ({ type }: { type: PromptType }) =>
    queryOptions({
      queryKey: [...copilotQueries.lists(), type],
      queryFn: async () => {
        const apiService = ApiService.getInstance();
        const prompts = await apiService.getCopilotPrompts({ type });
        return prompts.data as CopilotPromptType[];
      },
      staleTime: 5000,
    }),
};

const useCreateCopilotPromptMutation = () => {
  return useMutation({
    mutationFn: async (payload: Partial<CopilotPromptType>) => {
      const apiService = ApiService.getInstance();
      await apiService.createCopilotPrompt(payload);
    },
    mutationKey: copilotQueries.lists(),
  });
};

const useUpdateCopilotPromptMutation = () => {
  return useMutation({
    mutationFn: async (payload: Partial<CopilotPromptType>) => {
      const apiService = ApiService.getInstance();
      await apiService.updateCopilotPrompt(payload.id, payload);
    },
  });
};

const useDeleteCopilotPromptMutation = () => {
  return useMutation({
    mutationFn: async (payload: { id: number }) => {
      const apiService = ApiService.getInstance();
      await apiService.deleteCopilotPrompt(payload.id);
    },
  });
};

const useSelectCopilotPromptMutation = () => {
  return useMutation({
    mutationFn: async (payload: { id: number }) => {
      const apiService = ApiService.getInstance();
      await apiService.selectCopilotPrompt(payload.id);
    },
  });
};

function CopilotPromptEditModal({
  open,
  onClose,
  initialTitle,
  initialPrompt,
  onSubmit,
  onDelete,
}: {
  open: boolean;
  onClose: () => void;
  initialTitle?: string | null;
  initialPrompt?: string;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onDelete: () => void;
}) {
  const Actions = (
    <Layout direction="row" spacing={1} flex={1}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        label="Update"
        type="submit"
        form="copilot-prompt-form"
        sx={{ flex: 1 }}
      />
      <Button variant="contained" color="error" size="small" label="Delete" onClick={onDelete} sx={{ flex: 1 }} />
    </Layout>
  );

  return (
    <Drawer title="Edit prompt" onClose={onClose} open={open} Actions={Actions}>
      <Stack component="form" direction="column" spacing={2} onSubmit={onSubmit} flex={1} id="copilot-prompt-form">
        <TextField key={initialTitle} label="Title" name="title" sx={{ flex: 1 }} defaultValue={initialTitle} />
        <TextField
          key={initialPrompt}
          label="Prompt"
          multiline
          name="prompt"
          sx={{ flex: 1 }}
          defaultValue={initialPrompt}
          autoFocus
        />
      </Stack>
    </Drawer>
  );
}

function CopilotPromptForm({
  initialTitle,
  initialPrompt,
  onSubmit,
}: {
  initialTitle?: string;
  initialPrompt?: string;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}) {
  return (
    <Stack component="form" direction="column" spacing={1} onSubmit={onSubmit} flex={1}>
      <TextField label="Title" name="title" sx={{ flex: 1 }} defaultValue={initialTitle} />
      <TextField label="Prompt" multiline name="prompt" sx={{ flex: 1 }} defaultValue={initialPrompt} />
      <Button
        variant="contained"
        color="primary"
        size="small"
        label={initialPrompt ? "Update Prompt" : "Add Prompt"}
        type="submit"
        sx={{ alignSelf: "flex-end" }}
      />
    </Stack>
  );
}

function CopilotPrompt({ prompt }: { prompt: CopilotPromptType }) {
  const [collapsed, setCollapsed] = useState(true);
  const formattedPrompt = prompt.prompt.replace(/\n/g, "  \n");

  return (
    <Layout
      sx={{
        position: "relative",
        paddingBottom: collapsed ? 0 : "2rem",
        minHeight: "3rem",
      }}
      direction="column"
      spacing={1}
    >
      <Text variant="body1" bold>
        {prompt.title ?? "Untitled prompt"}
      </Text>
      <div className={`markdown-body ${collapsed ? "collapsed" : ""}`}>{formattedPrompt}</div>
      <Layout direction="row" alignItems="center">
        <IconButton
          onClick={() => setCollapsed(!collapsed)}
          icon={<Icon icon={collapsed ? faChevronDown : faChevronUp} />}
        />
      </Layout>
    </Layout>
  );
}

export function CopilotPromptsContent() {
  const [editingId, setEditingId] = useState<number | null>(null);
  const { user: adminUser } = useContext(UserContext);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const currentTab = TABS[currentTabIndex];
  const { data: prompts } = useQuery(copilotQueries.list({ type: currentTab?.value ?? PROMPT_TYPES.ADMIN }));
  const { mutate: createCopilotPrompt } = useCreateCopilotPromptMutation();
  const { mutate: updateCopilotPrompt } = useUpdateCopilotPromptMutation();
  const { mutate: selectCopilotPrompt } = useSelectCopilotPromptMutation();
  const { mutate: deleteCopilotPrompt } = useDeleteCopilotPromptMutation();

  if (!authorizedCopilotPromptUsersEmails.includes(adminUser?.email)) {
    return <div>You are not authorized to access this page</div>;
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const prompt = formData.get("prompt") as string;
    const title = formData.get("title") as string;
    if (editingId) {
      updateCopilotPrompt({ id: editingId, prompt, title });
      setEditingId(null);
    } else {
      createCopilotPrompt({ prompt, type: currentTab?.value ?? PROMPT_TYPES.ADMIN, title });
    }
    (e.target as HTMLFormElement).reset();
  };

  const handleDelete = (promptId: number | null) => {
    if (promptId) {
      deleteCopilotPrompt({ id: promptId });
      setEditingId(null);
    }
  };

  const columns = [
    {
      field: "prompt",
      headerName: "Prompt",
      flex: 1,
      minWidth: 700,
      renderCell: (params: { row: CopilotPromptType }) => {
        return <CopilotPrompt prompt={params.row} />;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      align: "right",
      headerAlign: "right",
      renderCell: (params: { row: CopilotPromptType }) => (
        <Layout direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
          {params.row.status === "active" ? (
            <Text bold>Current</Text>
          ) : (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              label="Select as current"
              onClick={() => selectCopilotPrompt({ id: params.row.id })}
            />
          )}
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            label="Edit"
            onClick={() => setEditingId(params.row.id)}
            startIcon={faEdit}
          />
        </Layout>
      ),
    },
  ];

  return (
    <Layout direction="column" spacing={4} paddingX={8}>
      <Heading variant="h2">Copilot prompts</Heading>
      <Tabs value={currentTabIndex} onChange={(_, value) => setCurrentTabIndex(value)}>
        {TABS.map((tab) => (
          <TabItem key={tab.value} label={<Text variant="body2">{tab.label}</Text>} />
        ))}
      </Tabs>
      <Layout direction="column" spacing={1}>
        <Text variant="body1" bold>
          Add a new prompt
        </Text>
        <CopilotPromptForm initialTitle="" initialPrompt="" onSubmit={handleSubmit} />
      </Layout>
      <Layout direction="column" spacing={1}>
        <Text variant="body1" bold>
          Variables
        </Text>
        <Text variant="body1">You can use the following variables in your prompts:</Text>
        <DataGrid
          rows={[{ id: 1, name: "{{company.name}}", description: "The name of the company" }]}
          columns={[
            { field: "name", headerName: "Variable" },
            { field: "description", headerName: "Description" },
          ]}
        />
      </Layout>
      <Layout direction="column" spacing={1}>
        <Text variant="body1" bold>
          Prompts
        </Text>
        <DataGrid rows={prompts ?? []} columns={columns} getRowHeight={() => "auto"} />
      </Layout>
      <CopilotPromptEditModal
        open={editingId !== null}
        onClose={() => setEditingId(null)}
        onDelete={() => handleDelete(editingId)}
        initialTitle={editingId ? prompts?.find((p) => p.id === editingId)?.title : undefined}
        initialPrompt={editingId ? prompts?.find((p) => p.id === editingId)?.prompt : undefined}
        onSubmit={handleSubmit}
      />
    </Layout>
  );
}

export function CopilotPrompts() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <CopilotPromptsContent />
    </Suspense>
  );
}
