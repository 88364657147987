import React, { useContext, useEffect } from "react";
import { Layout, Text, Button } from "@fleet.co/tarmac";
import styles from "../CompanyDiscount.module.scss";
import CompanyContext from "../../../../contexts/CompanyContext";
import { getRegionFromCountry, getCouponId, getMonthsFromCouponId } from "../../../../utils/couponUtils";

const DiscountTableMdmRow = (props) => {
  const { isEditable, discountInfos, mdmChangeDiscount, setMdmChangeDiscount } = props;
  const { company } = useContext(CompanyContext);

  // Initialize state when component mounts or when company changes
  useEffect(() => {
    if (company) {
      setMdmChangeDiscount((prevValue) => ({
        ...prevValue,
        mdm_coupon_id: company.mdm_coupon_id
      }));
    }
  }, [company]);

  const handleCouponChange = (months) => {
    const region = getRegionFromCountry(company.country);
    const couponId = months > 0 ? getCouponId(months, region) : "";

    setMdmChangeDiscount((prevValue) => {
      return {
        ...prevValue,
        mdm_coupon_id: couponId
      };
    });
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/[^\d.,]/g, "");
    const valueForState = inputValue.replace(/[.,]/g, "");

    setMdmChangeDiscount((prevValue) => ({
      ...prevValue,
      mdm_unit_amount: parseInt(valueForState) || 0,
    }));
  };

  const formatNumberForDisplay = (number) => {
    const adjustedNumber = number / 100;
    const formattedNumber = adjustedNumber.toFixed(2);
    return formattedNumber;
  };

  const getCurrentMonthsValue = () => {
    const region = getRegionFromCountry(company.country);
    return getMonthsFromCouponId(mdmChangeDiscount.mdm_coupon_id, region);
  };

  const handleDecrementMonths = () => {
    const currentMonths = getCurrentMonthsValue();
    if (currentMonths > 0) {
      handleCouponChange(currentMonths - 1);
    }
  };

  const handleIncrementMonths = () => {
    const currentMonths = getCurrentMonthsValue();
    if (currentMonths < 3) {
      handleCouponChange(currentMonths + 1);
    }
  };

  return (
    <Layout direction="row" fullWidth justifyContent="space-between" alignItems="center" className={styles.tableRow} sx={{ minHeight: "43px" }}>
      <Layout direction="column" className={styles.cellRow}>
        <Layout direction="column">
          <Text variant="body1" bold>
            {discountInfos.title}
          </Text>
          <Text variant="body2">{discountInfos.sub}</Text>
        </Layout>
      </Layout>
      {discountInfos.type === "info" && (
        <Text variant="body1">
          {discountInfos.value}
        </Text>
      )}
      {discountInfos.type === "coupon" && (
        <Layout direction="row" alignItems="center" sx={{ border: "1px solid #E0E0E0", borderRadius: "4px", overflow: "hidden" }}>
          <Button
            onClick={handleDecrementMonths}
            disabled={!isEditable || getCurrentMonthsValue() === 0}
            label="-"
            variant="text"
            size="small"
            sx={{
              color: "#000",
              minWidth: "32px",
              height: "32px",
              padding: 0,
              borderRadius: 0,
              borderRight: "1px solid #E0E0E0",
              "&:disabled": {
                color: "#E0E0E0",
              },
              "&:hover": {
                color: "#000",
                backgroundColor: "#F5F5F5"
              }
            }}
          />
          <Text sx={{ 
            width: "40px", 
            textAlign: "center",
            backgroundColor: "white"
          }}>
            {getCurrentMonthsValue()}
          </Text>
          <Button
            onClick={handleIncrementMonths}
            disabled={!isEditable || getCurrentMonthsValue() === 3}
            label="+"
            variant="text"
            size="small"
            sx={{
              color: "#000",
              minWidth: "32px",
              height: "32px",
              padding: 0,
              borderRadius: 0,
              borderLeft: "1px solid #E0E0E0",
              "&:disabled": {
                color: "#E0E0E0",
              },
              "&:hover": {
                color: "#000",
                backgroundColor: "#F5F5F5"
              }
            }}
          />
        </Layout>
      )}
      {discountInfos.type === "number" && (
        <Layout direction="row" alignItems="center">
          <Text variant="body1" sx={{ marginRight: 1 }}>€</Text>
          <input
            name="mdm_unit_amount"
            type="text"
            onChange={handleInputChange}
            value={formatNumberForDisplay(mdmChangeDiscount.mdm_unit_amount)}
            disabled={!isEditable}
            style={{
              width: "80px",
              padding: "8px",
              border: "1px solid #ccc",
              borderRadius: "4px"
            }}
          />
        </Layout>
      )}
    </Layout>
  );
};

export default DiscountTableMdmRow;
