import { COUNTRIES_LIST } from "src/common/i18n-consts";

export const types = ["DEVICE", "ACCESSORY", "ACOUSTIC", "SEAT", "TABLE"];

export const categories = [
  "COMPUTER",
  "TABLET",
  "SMARTPHONE",
  "SCREEN",
  "HEADPHONES",
  "KEYBOARD",
  "MOUSE",
  "ADAPTER",
  "SPEAKERS",
  "CHARGER",
  "CASE",
  "PHONEBOOTH",
  "CHAIR",
  "DESK",
];

export const brands = [
  { slug: "acer-chromebook", nicename: "Acer Chromebook" },
  { slug: "apple", nicename: "Apple" },
  { slug: "asus", nicename: "Asus" },
  { slug: "belkin", nicename: "Belkin" },
  { slug: "cherry", nicename: "Cherry" },
  { slug: "dell", nicename: "Dell" },
  { slug: "devialet", nicename: "Devialet" },
  { slug: "eizo", nicename: "EIZO" },
  { slug: "elgato", nicename: "Elgato" },
  { slug: "fleet", nicename: "Fleet" },
  { slug: "gentix", nicename: "Gentix" },
  { slug: "google", nicename: "Google" },
  { slug: "herman-miller", nicename: "Herman Miller" },
  { slug: "hp", nicename: "HP" },
  { slug: "iiyama", nicename: "iiyama" },
  { slug: "jabra", nicename: "Jabra" },
  { slug: "leet design", nicename: "Leet Design" },
  { slug: "lenovo", nicename: "Lenovo" },
  { slug: "logitech", nicename: "Logitech" },
  { slug: "microsoft", nicename: "Microsoft" },
  { slug: "nec", nicename: "NEC" },
  { slug: "offcoustic", nicename: "Offcoustic" },
  { slug: "persy booths", nicename: "Persy Booths" },
  { slug: "razer", nicename: "Razer" },
  { slug: "samsung", nicename: "Samsung" },
  { slug: "slean", nicename: "Slean" },
  { slug: "sony", nicename: "Sony" },
  { slug: "vseven", nicename: "VSeven" },
  { slug: "work-louder", nicename: "Work Louder" },
  { slug: "work-with-island", nicename: "Work with Island" },
  { slug: "xiaomi", nicename: "Xiaomi" },
  { slug: "yealink", nicename: "Yealink" },
];

export const productSpecs = [
  {
    applyTo: ["DEVICE"],
    label: "Operating System",
    name: "os_platform",
    key: "os_platform",
    type: "select",
    categories: ["COMPUTER", "TABLET", "SMARTPHONE"],
    options: (category) => {
      switch (category) {
        case "COMPUTER":
          return [
            { label: "-", value: "" },
            { label: "Linux", value: "linux" },
            { label: "Windows", value: "windows" },
            { label: "MacOS", value: "macos" },
          ];
        case "TABLET":
          return [
            { label: "-", value: "" },
            { label: "iPadOS", value: "ipados" },
            { label: "Android", value: "android" },
            { label: "Windows", value: "windows" },
          ];
        case "SMARTPHONE":
          return [
            { label: "-", value: "" },
            { label: "iOS", value: "ios" },
            { label: "Android", value: "android" },
            { label: "Windows", value: "windows" },
          ];
        default:
          return [];
      }
    },
  },
  {
    applyTo: ["DEVICE", "PRODUCT"],
    specSection: "characteristics",
    name: "screen_size",
    label: "Screen size",
    multiple: false,
    categories: ["COMPUTER", "TABLET", "SMARTPHONE", "SCREEN", "CASE"],
    options: (category) => {
      switch (category) {
        case "COMPUTER":
          return [
            { label: "-", value: "" },
            { label: "13", value: "13" },
            { label: "13.3", value: "13.3" },
            { label: "13.5", value: "13.5" },
            { label: "14", value: "14" },
            { label: "14.4", value: "14.4" },
            { label: "15", value: "15" },
            { label: "15.6", value: "15.6" },
            { label: "16", value: "16" },
            { label: "24", value: "24" },
            { label: "27", value: "27" },
          ];
        case "TABLET":
          return [
            { label: "-", value: "" },
            { label: "10.2", value: "10.2" },
            { label: "10.9", value: "10.9" },
            { label: "11", value: "11" },
            { label: "12.3", value: "12.3" },
            { label: "12.9", value: "12.9" },
            { label: "13", value: "13" },
          ];
        case "SMARTPHONE":
          return [
            { label: "-", value: "" },
            { label: "4.7", value: "4.7" },
            { label: "6.1", value: "6.1" },
            { label: "6.4", value: "6.4" },
            { label: "6.5", value: "6.5" },
            { label: "6.7", value: "6.7" },
          ];
        case "SCREEN":
          return [
            { label: "-", value: "" },
            { label: "15", value: "15" },
            { label: "16", value: "16" },
            { label: "17", value: "17" },
            { label: "18", value: "18" },
            { label: "19", value: "19" },
            { label: "21", value: "21" },
            { label: "22", value: "22" },
            { label: "23", value: "23" },
            { label: "24", value: "24" },
            { label: "25", value: "25" },
            { label: "26", value: "26" },
            { label: "27", value: "27" },
            { label: "28", value: "28" },
            { label: "29", value: "29" },
            { label: "30", value: "30" },
            { label: "31", value: "31" },
            { label: "32", value: "32" },
            { label: "34", value: "34" },
            { label: "43", value: "43" },
            { label: "45", value: "45" },
            { label: "49", value: "49" },
            { label: "50", value: "50" },
            { label: "55", value: "55" },
            { label: "65", value: "65" },
            { label: "70", value: "70" },
            { label: "75", value: "75" },
            { label: "77", value: "77" },
            { label: "83", value: "83" },
            { label: "85", value: "85" },
            { label: "98", value: "98" },
          ];
        case "CASE":
          return [
            { label: "-", value: "" },
            { label: "10.5", value: "10.5" },
            { label: "11", value: "11" },
            { label: "12.9", value: "12.9" },
            { label: "13", value: "13" },
            { label: "14", value: "14" },
            { label: "16", value: "16" },
          ];
        default:
          return [];
      }
    },
  },
  {
    applyTo: ["DEVICE", "PRODUCT"],
    specSection: "characteristics",
    name: "ram",
    label: "RAM",
    multiple: false,
    categories: ["COMPUTER", "TABLET", "SMARTPHONE"],
    options: (category) => {
      switch (category) {
        case "COMPUTER":
          return [
            { label: "-", value: "" },
            { label: "8 Go", value: "8 Go" },
            { label: "16 Go", value: "16 Go" },
            { label: "18 Go", value: "18 Go" },
            { label: "24 Go", value: "24 Go" },
            { label: "32 Go", value: "32 Go" },
            { label: "36 Go", value: "36 Go" },
            { label: "48 Go", value: "48 Go" },
            { label: "64 Go", value: "64 Go" },
            { label: "96 Go", value: "96 Go" },
            { label: "128 Go", value: "128 Go" },
          ];
        case "TABLET":
          return [
            { label: "-", value: "" },
            { label: "6 Go", value: "6 Go" },
            { label: "8 Go", value: "8 Go" },
            { label: "16 Go", value: "16 Go" },
          ];
        case "SMARTPHONE":
          return [
            { label: "-", value: "" },
            { label: "4 Go", value: "4 Go" },
            { label: "6 Go", value: "6 Go" },
            { label: "8 Go", value: "8 Go" },
            { label: "12 Go", value: "12 Go" },
          ];
        default:
          return [];
      }
    },
  },
  {
    applyTo: ["DEVICE", "PRODUCT"],
    specSection: "characteristics",
    name: "processor",
    label: "Processor",
    multiple: false,
    categories: ["COMPUTER", "TABLET", "SMARTPHONE"],
    options: (category) => {
      switch (category) {
        case "COMPUTER":
          return [
            { label: "-", value: "" },
            { label: "i5", value: "i5" },
            { label: "i3", value: "i3" },
            { label: "i7", value: "i7" },
            { label: "i9", value: "i9" },
            { label: "M1", value: "M1" },
            { label: "M1 Pro", value: "M1 Pro" },
            { label: "M1 Max", value: "M1 Max" },
            { label: "M2", value: "M2" },
            { label: "M2 Pro", value: "M2 Pro" },
            { label: "M2 Max", value: "M2 Max" },
            { label: "M2 Ultra", value: "M2 Ultra" },
            { label: "M3", value: "M3" },
            { label: "M3 Pro", value: "M3 Pro" },
            { label: "M3 Max", value: "M3 Max" },
            { label: "M4", value: "M4" },
            { label: "M4 Pro", value: "M4 Pro" },
            { label: "M4 Max", value: "M4 Max" },
            { label: "AMD Ryzen", value: "AMD Ryzen" },
            { label: "AMD Ryzen™ 5", value: "AMD Ryzen™ 5" },
            { label: "AMD Ryzen™ 7", value: "AMD Ryzen™ 7" },
            { label: "Core Ultra 5", value: "Core Ultra 5" },
            { label: "Core Ultra 7", value: "Core Ultra 7" },
            { label: "Core Ultra 9", value: "Core Ultra 9" },
            { label: "Snapdragon X Plus", value: "Snapdragon X Plus" },
            { label: "Snapdragon X Elite", value: "Snapdragon X Elite" },
          ];
        case "TABLET":
          return [
            { label: "-", value: "" },
            { label: "i5", value: "i5" },
            { label: "i7", value: "i7" },
            { label: "A14 Bionic", value: "A14 Bionic" },
            { label: "M1", value: "M1" },
            { label: "M2", value: "M2" },
            { label: "M4", value: "M4" },
            { label: "Core Ultra 5", value: "Core Ultra 5" },
            { label: "Core Ultra 7", value: "Core Ultra 7" },
            { label: "Core Ultra 9", value: "Core Ultra 9" },
          ];
        case "SMARTPHONE":
          return [
            { label: "-", value: "" },
            { label: "A13 Bionic", value: "A13 Bionic" },
            { label: "A14 Bionic", value: "A14 Bionic" },
            { label: "A15 Bionic", value: "A15 Bionic" },
            { label: "A16 Bionic", value: "A16 Bionic" },
            { label: "A17 Pro", value: "A17 Pro" },
            { label: "A18", value: "A18" },
            { label: "A18 Pro", value: "A18 Pro" },
            { label: "Exynos 1280", value: "Exynos 1280" },
            { label: "M4", value: "M4" },
            { label: "MediaTek Helio G88", value: "MediaTek Helio G88" },
            { label: "Snapdragon", value: "Snapdragon" },
            { label: "Tensor G2", value: "Tensor G2" },
            { label: "Core Ultra 5", value: "Core Ultra 5" },
            { label: "Core Ultra 7", value: "Core Ultra 7" },
            { label: "Core Ultra 9", value: "Core Ultra 9" },
          ];
        default:
          return [];
      }
    },
  },
  {
    applyTo: ["DEVICE", "PRODUCT"],
    specSection: "characteristics",
    name: "storage",
    label: "Storage",
    multiple: false,
    categories: ["COMPUTER", "TABLET", "SMARTPHONE"],
    options: (category) => {
      switch (category) {
        case "COMPUTER":
          return [
            { label: "-", value: "" },
            { label: "128 Go", value: "128 Go" },
            { label: "256 Go", value: "256 Go" },
            { label: "512 Go", value: "512 Go" },
            { label: "1 To", value: "1 To" },
            { label: "2 To", value: "2 To" },
            { label: "4 To", value: "4 To" },
            { label: "8 To", value: "8 To" },
          ];
        case "SMARTPHONE":
        case "TABLET":
          return [
            { label: "-", value: "" },
            { label: "64 Go", value: "64 Go" },
            { label: "128 Go", value: "128 Go" },
            { label: "256 Go", value: "256 Go" },
            { label: "512 Go", value: "512 Go" },
            { label: "1 To", value: "1 To" },
            { label: "2 To", value: "2 To" },
          ];
        default:
          return [];
      }
    },
  },
  {
    applyTo: ["DEVICE", "PRODUCT"],
    specSection: "characteristics",
    name: "graphic",
    label: "Graphic",
    multiple: false,
    categories: ["COMPUTER", "TABLET"],
    options: (category) => {
      switch (category) {
        case "COMPUTER":
          return [
            { label: "-", value: "" },
            { label: "Integrated", value: "Intégrée" },
            { label: "Intel® UHD", value: "Intel® UHD" },
            { label: "AMD Radeon", value: "AMD Radeon" },
            { label: "Intel® Iris®", value: "Intel® Iris®" },
            { label: "Intel® Iris® Xe", value: "Intel® Iris® Xe" },
            { label: "NVIDIA® GeForce RTX™ 3050 Ti avec 4 Go", value: "NVIDIA® GeForce RTX™ 3050 Ti avec 4 Go" },
            { label: "NVIDIA Quadro® T500 4 Go GDDR7", value: "NVIDIA Quadro® T500 4 Go GDDR7" },
            {
              label: "NVIDIA RTX™ 2000 Ada Generation Notebook-GPU 8GB GDDR6",
              value: "NVIDIA RTX™ 2000 Ada Generation Notebook-GPU 8GB GDDR6",
            },
            { label: "NVIDIA® RTX™ A500 4 Go GDDR6", value: "NVIDIA® RTX™ A500 4 Go GDDR6" },
            { label: "Nvidia GeForce RTX 4080", value: "Nvidia GeForce RTX 4080" },
            { label: "Nvidia GeForce RTX 4070 ", value: "Nvidia GeForce RTX 4070" },
            { label: "Nvidia GeForce RTX 4060", value: "Nvidia GeForce RTX 4060" },
            { label: "GPU 8 coeurs", value: "GPU 8 coeurs" },
            { label: "GPU 10 coeurs", value: "GPU 10 coeurs" },
            { label: "GPU 14 coeurs", value: "GPU 14 coeurs" },
            { label: "GPU 16 coeurs", value: "GPU 16 coeurs" },
            { label: "GPU 18 coeurs", value: "GPU 18 coeurs" },
            { label: "GPU 19 coeurs", value: "GPU 19 coeurs" },
            { label: "GPU 20 coeurs", value: "GPU 20 coeurs" },
            { label: "GPU 24 coeurs", value: "GPU 24 coeurs" },
            { label: "GPU 30 coeurs", value: "GPU 30 coeurs" },
            { label: "GPU 32 coeurs", value: "GPU 32 coeurs" },
            { label: "GPU 38 coeurs", value: "GPU 38 coeurs" },
            { label: "GPU 40 coeurs", value: "GPU 40 coeurs" },
            { label: "Qualcomm Adreno", value: "Qualcomm Adreno" },
          ];
        case "TABLET":
          return [
            { label: "-", value: "" },
            { label: "Integrated", value: "Intégrée" },
            { label: "Intel® Iris® Xe", value: "Intel® Iris® Xe" },
          ];
        default:
          return [];
      }
    },
  },
  {
    applyTo: ["DEVICE", "PRODUCT"],
    specSection: "characteristics",
    name: "connectivity",
    label: "Connectivity",
    multiple: false,
    categories: ["TABLET", "SMARTPHONE"],
    options: (category) => {
      switch (category) {
        case "TABLET":
          return [
            { label: "-", value: "" },
            { label: "WiFi", value: "WiFi" },
            { label: "WiFi + 4G", value: "WiFi + 4G" },
            { label: "WiFi + 5G", value: "WiFi + 5G" },
          ];
        case "SMARTPHONE":
          return [
            { label: "-", value: "" },
            { label: "4G", value: "4G" },
            { label: "5G", value: "5G" },
          ];
        default:
          return [];
      }
    },
  },
  {
    applyTo: ["DEVICE", "PRODUCT"],
    specSection: "characteristics",
    name: "quality",
    label: "Quality",
    multiple: false,
    categories: [
      "COMPUTER",
      "TABLET",
      "SMARTPHONE",
      "SCREEN",
      "HEADPHONES",
      "KEYBOARD",
      "MOUSE",
      "ADAPTER",
      "SPEAKERS",
      "CHARGER",
      "CASE",
      "PHONEBOOTH",
      "CHAIR",
      "DESK",
    ],
    options: () => [
      { label: "-", value: "" },
      { label: "refurb", value: "refurb" },
      { label: "new", value: "new" },
    ],
  },
  {
    applyTo: ["PRODUCT"],
    name: "available_countries",
    label: "Available countries",
    multiple: true,
    categories: [
      "COMPUTER",
      "TABLET",
      "SMARTPHONE",
      "SCREEN",
      "HEADPHONES",
      "KEYBOARD",
      "MOUSE",
      "ADAPTER",
      "SPEAKERS",
      "CHARGER",
      "CASE",
      "PHONEBOOTH",
      "CHAIR",
      "DESK",
    ],
    options: () => [
      { label: "-", value: "" },
      ...COUNTRIES_LIST.map((country) => ({ label: country, value: country })),
    ],
  },
  {
    applyTo: ["DEVICE"],
    name: "keyboard_layout",
    label: "Keyboard Layout",
    multiple: false,
    categories: ["KEYBOARD", "COMPUTER"],
    options: () => [
      { label: "-", value: "" },
      { label: "FR - French - AZERTY", value: "FR - Français - AZERTY" },
      { label: "ENG - International English - QWERTY", value: "ENG - Anglais international - QWERTY" },
      { label: "DE - German - QWERTZ", value: "DE - Allemand - QWERTZ" },
      { label: "UK - Anglais - QWERTY", value: "UK - Anglais - QWERTY" },
      { label: "US - American English - QWERTY", value: "US - Anglais américain - QWERTY" },
      { label: "ES - Spanish - QWERTY", value: "ES - Espagnol - QWERTY" },
      { label: "IT - Italian - QWERTY", value: "IT - Italien - QWERTY" },
      { label: "PT - Portuguese - QWERTY", value: "PT - Portugais - QWERTY" },
      { label: "CH - Swiss - QWERTZ", value: "CH - Suisse - QWERTZ" },
      { label: "Other", value: "other" },
    ],
  },
  {
    applyTo: ["PRODUCT"],
    specSection: "characteristics",
    name: "has_keyboard",
    label: "Has keyboard",
    multiple: false,
    categories: ["COMPUTER"],
    options: () => [
      { label: "-", value: "" },
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "noise_cancellation",
    label: "Noise cancellation",
    multiple: false,
    categories: ["HEADPHONES"],
    options: () => [
      { label: "-", value: "" },
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "has_microphone",
    label: "Microphone",
    multiple: false,
    categories: ["HEADPHONES", "SPEAKERS"],
    options: () => [
      { label: "-", value: "" },
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "connection_type",
    label: "Connection type",
    multiple: true,
    categories: ["SCREEN", "MOUSE", "KEYBOARD", "CHARGER", "ADAPTER", "HEADPHONES", "SPEAKERS"],
    options: (category) => {
      switch (category) {
        case "SCREEN":
          return [
            { label: "-", value: "" },
            { label: "HDMI", value: "hdmi" },
            { label: "DisplayPort", value: "displayport" },
            { label: "VGA", value: "vga" },
            { label: "USB-C", value: "usb-c" },
            { label: "USB", value: "usb" },
            { label: "Ethernet", value: "ethernet" },
          ];
        case "CHARGER":
        case "ADAPTER":
          return [
            { label: "-", value: "" },
            { label: "USB-C", value: "usb-c" },
            { label: "USB", value: "usb" },
          ];
        case "MOUSE":
        case "KEYBOARD":
          return [
            { label: "-", value: "" },
            { label: "Wireless", value: "wireless" },
            { label: "USB", value: "usb" },
          ];
        case "HEADPHONES":
        case "SPEAKERS":
          return [
            { label: "-", value: "" },
            { label: "Wireless", value: "wireless" },
            { label: "USB", value: "usb" },
            { label: "USB-C", value: "usb-c" },
            { label: "Jack", value: "jack" },
            { label: "Ethernet", value: "ethernet" },
          ];
        default:
          return [];
      }
    },
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "compliance",
    label: "Compliance",
    multiple: true,
    categories: ["SCREEN", "HEADPHONES", "KEYBOARD", "MOUSE", "ADAPTER", "SPEAKERS", "CHARGER", "CASE"],
    options: () => [
      { label: "-", value: "" },
      { label: "Mac", value: "mac" },
      { label: "iPad", value: "ipad" },
      { label: "Tablet", value: "tablet" },
      { label: "Smartphone", value: "smartphone" },
      { label: "PC", value: "pc" },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "has_webcam",
    label: "Webcam",
    multiple: false,
    categories: ["SPEAKERS"],
    options: () => [
      { label: "-", value: "" },
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "refresh_rate",
    label: "Refresh rate",
    multiple: false,
    categories: ["SCREEN"],
    options: () => [
      { label: "-", value: "" },
      { label: "60Hz", value: "60Hz" },
      { label: "75Hz", value: "75Hz" },
      { label: "165Hz", value: "165Hz" },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "response_time",
    label: "Response time",
    multiple: false,
    categories: ["SCREEN"],
    options: () => [
      { label: "-", value: "" },
      { label: "1 ms", value: "1 ms" },
      { label: "2 ms", value: "2 ms" },
      { label: "3 ms", value: "3 ms" },
      { label: "4 ms", value: "4 ms" },
      { label: "5 ms", value: "5 ms" },
      { label: "6 ms", value: "6 ms" },
      { label: "7 ms", value: "7 ms" },
      { label: "8 ms", value: "8 ms" },
      { label: "9 ms", value: "9 ms" },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "screen_stand",
    label: "Screen stand",
    multiple: true,
    categories: ["SCREEN"],
    options: () => [
      { label: "-", value: "" },
      { label: "Rotatable", value: "rotatable" },
      { label: "Adjustable", value: "adjustable" },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "definition",
    label: "Definition",
    multiple: false,
    categories: ["SCREEN"],
    options: () => [
      { label: "-", value: "" },
      { label: "HD", value: "HD" },
      { label: "Full HD", value: "Full HD" },
      { label: "Quad HD", value: "Quad HD" },
      { label: "4K", value: "4K" },
      { label: "5K", value: "5K" },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "optimized_to",
    label: "Optimized to",
    multiple: false,
    categories: ["SPEAKERS"],
    options: () => [
      { label: "-", value: "" },
      { label: "8", value: "8" },
      { label: "15", value: "15" },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "equipment_sub_type",
    label: "Equipment subtype",
    multiple: false,
    categories: ["HEADPHONES", "ADAPTER"],
    options: (category) => {
      switch (category) {
        case "HEADPHONES":
          return [
            { label: "-", value: "" },
            { label: "Headphones", value: "HEADPHONES" },
            { label: "Earphones", value: "EARPHONES" },
          ];
        case "ADAPTER":
          return [
            { label: "-", value: "" },
            { label: "Cable", value: "CABLE" },
            { label: "Adapter", value: "ADAPTER" },
            { label: "Power plug", value: "POWER_PLUG" },
          ];
        default:
          return [];
      }
    },
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "battery_life",
    label: "Battery life",
    multiple: false,
    categories: ["HEADPHONES", "SPEAKERS", "MOUSE"],
    options: () => [
      { label: "-", value: "" },
      { label: "5h", value: "5h" },
      { label: "10h", value: "10h" },
      { label: "11h", value: "11h" },
      { label: "20h", value: "20h" },
      { label: "32h", value: "32h" },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "power",
    label: "Power",
    multiple: false,
    categories: ["ADAPTER", "CHARGER"],
    options: () => [
      { label: "-", value: "" },
      { label: "18W", value: "18W" },
      { label: "45W", value: "45W" },
      { label: "60W", value: "60W" },
      { label: "85W", value: "85W" },
      { label: "96W", value: "96W" },
      { label: "140W", value: "140W" },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "has_stool",
    label: "Has stool",
    multiple: false,
    categories: ["PHONEBOOTH"],
    options: () => [
      { label: "-", value: "" },
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "has_video_package",
    label: "Has video package",
    multiple: false,
    categories: ["PHONEBOOTH"],
    options: () => [
      { label: "-", value: "" },
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "has_screen",
    label: "Has screen",
    multiple: false,
    categories: ["PHONEBOOTH"],
    options: () => [
      { label: "-", value: "" },
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "has_premium_tablet",
    label: "Has premium tablet",
    multiple: false,
    categories: ["PHONEBOOTH"],
    options: () => [
      { label: "-", value: "" },
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "color",
    label: "Color",
    multiple: false,
    categories: ["PHONEBOOTH"],
    options: () => [
      { label: "-", value: "" },
      { label: "Heather gray", value: "heather gray" },
      { label: "White", value: "white" },
      { label: "Black", value: "black" },
      { label: "Grey", value: "grey" },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "seats_number",
    label: "Number of seats",
    multiple: false,
    categories: ["PHONEBOOTH"],
    options: () => [
      { label: "-", value: "" },
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "4", value: "4" },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "assembly",
    label: "Delivery and assembly",
    multiple: false,
    categories: ["PHONEBOOTH"],
    options: () => [
      { label: "-", value: "" },
      { label: "Included", value: "included" },
      { label: "Not Included", value: "not-included" },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "dimensions",
    label: "Dimensions",
    multiple: false,
    categories: ["PHONEBOOTH", "DESK"],
    options: (category) => {
      switch (category) {
        case "PHONEBOOTH":
          return [
            { label: "-", value: "" },
            { label: "110 x 105 x 225", value: "110 x 105 x 225" },
            { label: "115 x 95 x 215", value: "115 x 95 x 215" },
            { label: "210 x 95 x 215", value: "210 x 95 x 215" },
            { label: "210 x 125 x 215", value: "210 x 125 x 215" },
            { label: "215 x 105 x 225", value: "215 x 105 x 225" },
            { label: "216 x 133 x 225", value: "216 x 133 x 225" },
            { label: "219 x 113 x 109", value: "219 x 113 x 109" },
            { label: "219 x 205 x 109", value: "219 x 205 x 109" },
            { label: "219 x 205 x 139", value: "219 x 205 x 139" },
            { label: "211 x 115 x 97 ", value: "211 x 115 x 97 " },
          ];
        case "DESK":
          return [
            { label: "-", value: "" },
            { label: "60 x 45", value: "60 x 45" },
            { label: "75 x 45", value: "75 x 45" },
            { label: "100 x 60", value: "100 x 60" },
            { label: "120 x 60", value: "120 x 60" },
            { label: "120 x 130", value: "120 x 130" },
            { label: "131 x 70", value: "131 x 70" },
            { label: "160 x 80", value: "160 x 80" },
            { label: "220 x 80", value: "220 x 80" },
            { label: "220 x 100", value: "220 x 100" },
          ];
        default:
          return [];
      }
    },
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "has_power_plug",
    label: "Has power plug",
    multiple: false,
    categories: ["DESK"],
    options: () => [
      { label: "-", value: "" },
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "has_adjustable_height",
    label: "Has adjustable height",
    multiple: false,
    categories: ["DESK"],
    options: () => [
      { label: "-", value: "" },
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "has_cable_management",
    label: "Has cable management",
    multiple: false,
    categories: ["DESK"],
    options: () => [
      { label: "-", value: "" },
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "has_wheels",
    label: "Has wheels",
    multiple: false,
    categories: ["CHAIR"],
    options: () => [
      { label: "-", value: "" },
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "armrest",
    label: "Armrest",
    multiple: false,
    categories: ["CHAIR"],
    options: () => [
      { label: "-", value: "" },
      { label: "None", value: "none" },
      { label: "Adjustable", value: "adjustable" },
      { label: "Fixed", value: "fixed" },
    ],
  },
  {
    applyTo: ["PRODUCT", "DEVICE"],
    specSection: "characteristics",
    name: "lumbar_support",
    label: "Lumbar support",
    multiple: false,
    categories: ["CHAIR"],
    options: () => [
      { label: "-", value: "" },
      { label: "Classic", value: "classic" },
      { label: "Lumbar Support", value: "lumbar-support" },
      { label: "Adjustable lumbar support", value: "lumbar-support-adjustable" },
    ],
  },
];
